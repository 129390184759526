@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}

body {
  height: 100%;
}
input {
  @apply bg-gray-200 border focus:outline-none focus:bg-white focus:border border-gray-200;
}
#root {
  height: 100%;
}
.unauthorized-layout {
  @apply h-full bg-slate-50 bg-opacity-25;
}
.authorized-layout {
  @apply h-screen w-screen bg-customBlue overflow-hidden;
}
.alert-container {
  @apply w-60 fixed bottom-2 left-2 pointer-events-none;
}
.alert-error {
  @apply bg-red-300 rounded-md text-black min-h-16 max-h-fit;
}
.alert-error .alert-message {
  @apply text-red-800 text-sm font-medium;
}
.alert-error .alert-close {
  @apply text-red-800 opacity-70 font-medium;
}
.alert-error .alert-loader {
  @apply bg-red-500;
}
.alert-success {
  @apply bg-green-300 rounded-md text-black min-h-16 max-h-fit;
}
.alert-success .alert-message {
  @apply text-green-800 text-sm font-medium;
}
.alert-success .alert-close {
  @apply text-green-800 opacity-70 font-medium;
}
.alert-success .alert-loader {
  @apply bg-green-500;
}
.search-chat-input {
  @apply w-full py-2 px-6 rounded-3xl mb-4 placeholder:text-sm;
}
.backdrop {
  @apply bg-[#00000080] -z-[1];
}
.opacity-animation {
  animation: fadeIn 225ms cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}
.modal {
  @apply relative;
}
.close-modal {
  @apply absolute right-0 top-0 bg-red-800 text-white rounded-full p-1 translate-x-1/2 -translate-y-1/2 cursor-pointer;
}
.mic-level-icon-container {
  --audio-level: 0; /* Default value */
  --min-size: 190px;
  --max-size: 19rem;
  --range: calc(var(--max-size) - var(--min-size));
  --increase: calc(var(--audio-level) * var(--range));
  width: calc(var(--min-size) + var(--increase));
  height: calc(var(--min-size) + var(--increase));
  background: rgba(0, 128, 0, 0.2);
  transition: width 0.01s ease, height 0.01s ease;
}
.mic-level-icon {
  --mic-div-background: #fff; /* Default value */
  transition: background-color 0.3s ease;
  background-color: var(--mic-div-background);
}
.mic-level-icon svg {
  --svg-background: #991b1b; /* Default value */
  transition: background-color 0.3s ease;
  fill: var(--svg-background) !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* NightSoft Dropdown Css - Tailwind */
.ns-list-dropdown > *:not(:last-child) {
  @apply border-b;
}

.ns-list-dropdown {
  @apply absolute bg-white border cursor-default min-w-28 rounded-sm;
}
.ns-list-dropdown-item {
  @apply cursor-pointer text-sm px-2 py-1;
}
.ns-list-dropdown-item:hover {
  @apply bg-customBlue text-black;
}

.audio-playback {
  @apply bg-black text-white;
}
.audio-playback > .ns-list-dropdown-item {
  @apply text-center text-xs transition-all;
}
.audio-playback > .ns-list-dropdown-item:hover {
  @apply bg-gray-800 text-white scale-110;
}

.img-progress-overlay {
  /* --img-progress: 0%; Default value */
  /* height: var(--img-progress); */
  background-color: #ffffff66;
  height: 100%;
}
